import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import { required, email, is, min, alpha, alpha_spaces } from 'vee-validate/dist/rules';
import { i18n } from '@/plugins/i18n'
// import Toast from "vue-toastification";
// Import the CSS or use your own!
// import "vue-toastification/dist/index.css";
import VueTheMask from 'vue-the-mask'

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver)

extend('email', email);
extend('required', required);
extend('min', min);
extend('alpha', alpha);
extend('alpha_spaces', alpha_spaces);
extend('is', {
  ...is,
  message: (_, values) => i18n.t('messages.passwordMatch', values)
});

Vue.use(VueTheMask)

// vue-toastification
// const options = {
//   // You can set your default options here
//   position: "bottom-right",
//   icon: false,
//   toastClassName: "va-toast alert",
//   bodyClassName: "va-toast-body",
//   hideProgressBar: true
// };

// Vue.use(Toast, options);