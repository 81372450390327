import Vue from 'vue'
import VueRouter from 'vue-router'
// import AuthLayout from '@/views/layouts/auth/TheAuthLayout'
import WebLayout from '@/views/layouts/web/TheWebLayout'
// import AppLayout from '@/views/layouts/app/TheAppLayout'
// import BillingLayout from '@/views/layouts/app/BillingLayout'
// import ProfileLayout from '@/views/layouts/app/ProfileLayout'
// import AppContentLayout from '@/views/layouts/app/TheAppContentLayout'
import {Home} from '@/views/pages/web'
// import {Login, Register, ResetPassword, Recover, AccountActivation, AccountActivationCode} from '@/views/pages/auth'
// import {Dashboard, Profile, Notification, Api, Subscription, BillingDetails, Catalog, CatalogDetail} from '@/views/pages/app'
import NotFoundPage from '@/views/pages/NotFound'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/auth',
  //   component: AuthLayout,
  //   meta: {
  //     guest: true
  //   },
  //   redirect: '/',
  //   children: [
  //     {
  //       path: '/login',
  //       name: 'login',
  //       component: Login
  //     },
  //     {
  //       path: '/register',
  //       name: 'register',
  //       component: Register
  //     },
  //     {
  //       path: '/reset',
  //       name: 'reset',
  //       component: ResetPassword
  //     },
  //     {
  //       path: '/recover/:id',
  //       name: 'recover',
  //       component: Recover
  //     },
  //     {
  //       path: '/activate/code',
  //       name: 'accountActivationCode',
  //       component: AccountActivationCode
  //     }
  //   ]
  // },
  // {
  //   path: '/activate/:userUuid/:activationCode',
  //   name: 'accountActivation',
  //   component: AccountActivation
  // },
  {
    path: '/',
    component: WebLayout,
    meta: {
      guest: true
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      }
    ]
  },
  // {
  //   path: '/app',
  //   component: AppLayout,
  //   meta: {
  //     auth: true
  //   },
  //   children: [
  //     { 
  //       path: '', 
  //       component: AppContentLayout,
  //       redirect: {name: 'dashboard'},
  //       children: [
  //         {
  //           path: '/app/dashboard',
  //           name: 'dashboard',
  //           component: Dashboard
  //         },
  //         {
  //           path: '/app/catalog',
  //           name: 'catalog',
  //           component: Catalog,
  //           children: [
              
  //           ]
  //         },
  //         {
  //           path: '/app/catalog/:id',
  //           name: 'catalog-detail',
  //           component: CatalogDetail
  //         },
  //         {
  //           path: '/app/api',
  //           name: 'api',
  //           component: Api
  //         },
  //         {
  //           path: '/app/billing',
  //           name: 'billing',
  //           component: BillingLayout,
  //           redirect: { name: 'subscription' },
  //           children: [
  //             {
  //               path: '/app/billing/subscription',
  //               name: 'subscription',
  //               component: Subscription
  //             },
  //             {
  //               path: '/app/billing/details',
  //               name: 'billing-details',
  //               component: BillingDetails
  //             }
  //           ]
  //         },
  //         {
  //           path: '/app/profile',
  //           name: 'profile',
  //           component: ProfileLayout,
  //           redirect: { name: 'profile-settings' },
  //           children: [
  //             {
  //               path: '/app/profile/settings',
  //               name: 'profile-settings',
  //               component: Profile
  //             },
  //             {
  //               path: '/app/profile/notification-settings',
  //               name: 'notification-settings',
  //               component: Notification
  //             }
  //           ]
  //         }
  //       ] 
  //     }
  //   ]
  // },
  {
    path: '*',
    name: '404',
    component: NotFoundPage,
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name!='subscription'){store.dispatch("app/setLastActionDate")}
  let _isAuth = store.state.auth.authenticated;
  let _userStatus = store.getters["auth/getUserStatus"];
  if (to.matched.some((record) => record.meta.auth)) {
    if(_isAuth && _userStatus == "p") {
      next({name: "accountActivationCode"})
      return
    }
    else if (_isAuth) {
      next()
      return
    }
    next('/login')
  } else {
    next();
  }
});

Vue.router = router

export default router
