import { mapActions } from "vuex"
export default {
  computed: {
    locales() {
      let locales = this.$i18n.availableLocales;
      let indexOf = this._.indexOf(locales, this.$i18n.locale);
      locales.splice(indexOf, 1)
      return locales;
    }
  },
  methods: {
    ...mapActions("global", ["setLocale"]),
    onClickLocaleDropdown(locale) {
      this.setLocale(locale);
      this.$router.go();
    }
  }
}