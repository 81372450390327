export default {
  home: {
    banner_title: "Ürün geliştirmenize yardımcı oluyoruz.",
    banner_text_1: "İster bir şirket listesi, ister bir posta kodu koleksiyonu, yeni hazırlanmış trivia soruları veya kokteyl tarifleri olsun, her uygulamanın veriye ihtiyacı vardır.",
    banner_text_2: "ViaApi'de, en basit veri setini bile derlemenin ve sürdürmenin ne kadar zaman alıcı olduğunu biliyoruz ve en çok gözden kaçan maliyetlerinizi düşürmek ve dengelemek için buradayız.",
    about: {
      whatWeOffer: "Ne sunuyoruz",
      compiledData: "Derlenmiş Veri",
      compiledData_text: "Çoğu durumda kendi başınıza kolayca derleyebileceğiniz, ancak bakımı çok kolay olmayan ve sürekli güncel olmayan açık veriler sağlıyoruz. Verileri derliyor, verilere değer katıyor, güncel tutuyoruz, ondan anlam çıkarıyor ve api uç noktalarımız ve diğer teslimat yöntemlerimizle talep üzerine size hizmet ediyoruz. Coğrafi veriler, trivia verileri, ilgi alanları ve daha fazlasını içeren çeşitli setlerin verilerini elle topladık ...",
      qualityAssurance: "Kalite Güvencesi",
      qualityAssurance_text: "Çeşitli kaynaklardan veri topluyoruz, çapraz kontrol ediyor ve en doğru kombinasyonu seçiyoruz. Verilerin geçerliliğini sürekli kontrol ediyor, çapraz ilişki kuruyor, güncelliyor ve sistematik olarak arşivliyoruz, böylece ihtiyacınız olan tüm özelliklere ve sürümlere her zaman aynı anda erişebilirsiniz.",
      easeOfAccess: "Erişim Kolaylığı",
      easeOfAccess_text: "API'lerimiz aracılığıyla hem indirilebilir veri setleri hem de işlemsel veri teslimatı sunuyoruz. Verileri, elektronik tablolar, CSV dosyaları, JSON belgeleri ve daha fazlası dahil olmak üzere hayal edebileceğiniz hemen hemen her formatta indirebilirsiniz. İndirilebilir veri kümeleri için bile ihtiyacınız olan özelliklere ince ayar yapabilir ve yalnızca ihtiyacınız olan verilerin işlem ve bakım maliyetlerini ödeyebilirsiniz."
    },
    startNow: "ŞİMDİ BAŞLA"
  },
  login: {
    card_title: "Hesabına giriş yap",
    forgot_password_text: "Şifreni mi unuttun?",
    haveAccount_text: "Hesabın yok mu?",
    stay_signed_text: "Bir hafta çevrimiçi kal"
  },
  register: {
    activateEmail: `{email} adresine e-posta gönderdik. E-postayı açıp hesabınızı aktive edin.`,
    card_title: "ViaApi hesabını oluştur",
    card_title_activate: "Neredeyse tamam...",
    privacy_text1: "Kişisel verileriniz, ",
    privacy_text2: "Aydınlatma Metni ",
    privacy_text3: "kapsamında işlenicektir. ",
    privacy_text4: "'Üye Ol' butonuna basarak ",
    privacy_text5: "Kullanım Şartları ",
    privacy_text6: "'nı, ",
    privacy_text7: "Rıza Metni",
    privacy_text8: "'ni, ",
    privacy_text9: "Çerez Politikası",
    privacy_text10: "'nı okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.",
    privacy_text14: "ViaApi'den ürün güncellemeleri, sektör haberleri ve etkinlikler hakkında e-postalar alın. Fikrini değiştirirsen, istediğin zaman abonelikten çıkabilirsin",
    announcement_text1: "Önemli kampanyalardan haberdar olmak için ",
    announcement_text2: "Rıza Metni",
    announcement_text3: " kapsamında elektronik ileti almak istiyorum.",
    haveAccount_text: "Hesabın var mı?",
    clarificationTitle: "Aydınlatma Metni",
    clarificationText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    termsOfUseTitle: "Kullanım Şartları",
    termsOfUseText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    cookiePolicyTitle: "Çerez Politikası",
    cookiePolicyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    consentTitle: "Rıza Metni",
    consentText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    passwordStrength: "Şifrenizi kırılması zor hale getirmek için en az bir büyük harf, bir rakkam ve bir sembol kullanınız"
  },
  resetPassword: {
    card_title: "Şifreni sıfırla",
    card_title_success: "Teşekkürler, e-postanı sıfırlama talimatları için e-postanı kontrol et",
    cardBody_text: "Hesabınla ilişkili e-posta adresini gir ve şifreni sıfırlaman için bir bağlantı gönderelim.",
    cardBody_text_success: "E-postayı almadın mı? Spam klasörünü kontrol et veya",
    returnToLogin: "Giriş sayfasına dön"
  },
  accountActivationCode: {
    activationCodeFail_text: "There was a problem with the activation link. Please type your email and we'll send you a new activation link.",
    card_title: "Neredeyse bitti. Size bir e-posta gönderdik.",
    cardBody_text: "Lütfen {email} e-posta adresine gönderdiğimiz kodu girin.",
  },
  catalog: {
    selectPlan_text: "Kataloğumuzun tamamını görmek için bir plan seçmeniz gerekiyor.",
    selectPlan: "Plan Seç"
  },
  subscription: {
    additionalAttributes: "Ek Özelllikler",
    addPaymentMethod: "Ödeme Yöntemi Ekle",
    askPassword: "Bu kısmı görüntüleyebilmek için şifrenizi girmeniz gerekmektedir.",
    cancelSubscription: "Üyeliği iptal et",
    close: "Değiştir",
    activeSubscriptionAlert_title:'Aktif Üyelik Uyarısı',
    activeSubscriptionAlert_text:'Aktif bir üyeliğiniz bulunmaktadır. Lütfen önce onu iptal edin.',
    cancelSubscription_text: "Üyeliğini iptal etmek istediğine emin misin?",
    currentPlan: "Mevcut planın",
    dataSets: "Veri Kümesi",
    deletePaymentMethodDialog_title: "Ödeme yöntemini sil",
    deletePaymentMethodDialog_text: "Ödeme yöntemini silmek istediğine emin misin?",
    fileExport: "Dosya Aktarımı (XLSX, PDF, CSV)",
    noPaymentMethod_title: "Ödeme yöntemi yok",
    noPaymentMethod_text: "Bir ödeme yöntemi eklemeniz gerekiyor.",
    paymentMethods: "Ödeme yöntemleri",
    paymentMethodDialog_title: "Henüz bir ödeme yöntemin yok",
    paymentMethodDialog_text: "Bir abonelik paketi seçmeden önce bir ödeme yöntemi eklemen gerekir",
    prioritySupport: "Öncelikli Destek",
    requests: "İstekler",
    selectPlan_text: "Henüz bir paket seçimi yapmadınız.",
    updatePaymentMethod: "Ödeme yöntemi güncelleme"
  },
  api: {
    apiId: "API Anahtarı",
    apiKeyName: "API anahtar ismi",
    createToken: "API anahtarı oluştur",
    createTokenNow: "Şimdi Oluştur",
    createToken_text: "API'a talepte bulunabilmen için önce API anahtarı oluşturmanız gerekir.",
    documentation: "Dokümentasyonumuzda daha fazlasını öğrenin.",
    explanatory_text: "API'a talepte bulunabilmen için önce API anahtarı oluşturmanız gerekir.ViaAPI Servis Dökimanı hakkında daha fazla bilgi için",
    learn_more: "buraya tıklayın",
    deleteApiKey_title: "API anahtarını sil",
    deleteApiKey_text: "API anahtarını silmek istediğine emin misin?",
    noTokens_text: "Henüz API anahtarı yok",
    updateToken: "API anahtar açıklaması güncelle"
  },
  general: {
    about: "Hakkımızda",
    activationCode: "Aktivasyon Kodu",
    address: "Adres",
    api: "API",
    apiUsage: "API Kullanımı",
    billing: "Fatura İşlemleri",
    billingDetails: "Fatura Detayları",
    cancel: "İptal",
    cardNumber: "Kart numarası",
    catalog: "Katalog",
    city: "Şehir",
    close: "İptal",
    company: "Şirket",
    companyName: "Şirket adı",
    confirm: "Onayla",
    contact: "İletişim",
    continue: "Devam",
    copied: "Kopyalandı",
    country: "Ülke",
    created: "Oluşturuldu",
    creditCard: "Kredi Kartı",
    delete: "Sil",
    dashboard: "Kontrol Paneli",
    difficulty: "Zorluk",
    district: "İlçe",
    downloadPdf: "PDF İndir",
    sendEmail: "Eposta Gönder",
    email: "E-posta",
    emailChange: "E-posta Değiştir",
    emailNew: "Yeni E-posta",
    enterPassword: "Şifre Girişi",
    expirationDate: "Son Kullanma Tarihi",
    expirationMonth: "Ay",
    expirationYear: "Yıl",
    firstName: "İsim",
    invoiceType: "Fatura tipi",
    invoices: "Faturalar",
    openinvoice: "Yeni Fatura",
    paidinvoice: "Ödenmiş Fatura",
    voidinvoice: "Silinmiş Fatura",
    lastName: "Soyad",
    login: "Giriş",
    logout: "Çıkış",
    metrics: "Metrikler",
    month: "Ay",
    name: "İsim",
    nameOnCard: "Kart üzerindeki isim",
    notifications: "Bildirimler",
    or: "veya",
    password: "Şifre",
    passwordChange: "Şifreyi değiştir",
    passwordConfirm: "Yeni şifre tekrarı",
    passwordNew: "Yeni Şifre",
    passwordOld: "Güncel Şifre",
    person: "Şahıs",
    personalInformation: "Kişisel Bilgiler",
    pricing: "Fiyatlar",
    privacy: "Gizlilik & Şartlar",
    privacyPolicy: "Gizlilik Politikası",
    profile: "Profil",
    profileSettings: "Profil Ayarları",
    resend: "Tekrar gönder",
    resent: "Gönderildi!",
    save: "Kaydet",
    securityCode: "CVV2",
    select: "Seç",
    selectCountry: "Ülke seç",
    settings: "AYARLAR",
    signIn: "Giriş",
    signUp: "Kaydol",
    state: "Eyalet",
    subscription: "Üyelik",
    taxId: "Vergi No.",
    thisMonth: "Bu ay",
    today: "Bugün",
    totalCalls: "Toplam Kullanım",
    question: "Soru",
    hint:"İpucu",
    word:"Kelime",
    update: "Güncelle",
    verify: "Doğrula",
    year: "Yıl",
    zipCode: "Posta Kodu",
    onpackge:"Paketiniz",
    passRequiredTag: "Şifrenizi Girmeniz Gerekmektedir",
    passRequiredDesc: "Yapmak istediğiniz işlem için şifrenizi girmeniz gerekmektedir",
    validateBtn: "Doğrula"
  },
  locales: {
    "en-us": "English",
    "tr-tr": "Türkçe"
  },
  notifications: {
    emailMeAbout: "Lütfen bildirim almak istediğiniz başlıkları seçiniz",
    notFound: "Şu anda bildiriminiz yok.",
    markAsReadAll: "Tümünü okundu işaretle",
    permissions : {
      invoiceReminder: "Fatura güncellemelerinde",
      limitReminder: "Api kullanım limitleri",
      paymentReminder: "Ödeme ve ödeme yöntemi değişikliklerinde",
      priceUpdatesReminder: "Promosyon ve katalog güncellemelerinde.",
      subscriptionReminder: "Abonelik güncellemelerinde."
    }
  },
  messages: {
    passwordMatch: "Yeni Şifre ve tekrarı birbirinin aynı olmalı.",
    passwordUpdateSuccess: "Şifre başarıyla güncellendi.",
    personalInformationUpdateSuccess: "Kişisel bilgiler başarıyla değiştirildi.",
    permissionsUpdateSuccess: "İzinler başarıyla güncellendi."
  },
  account: {
    action: "Hesap İşlemleri",
    close: "Hesabı Kapat",
    activate: "Hesabı Aktif Et",
    closeText: "Hesabınızı kapatmak istediğinize eminmisiniz?",
  },
  client: {
    suspendTitle: "Firma Engellendi",
    suspendText: "Girmiş olduğunuz ödeme yöntemi dolayısıyla firma hesabınız engellendi, lütfen ödeme yöntemleriniz kontrol ediniz"
  }
}