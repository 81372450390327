export default {
  home: {
    banner_title: "Helping companies to build products",
    banner_text_1: "Whether a list of companies, a collection of zip codes, freshly baked trivia questions or cocktail recipes, every app needs data.",
    banner_text_2: "At ViaApi, we know how time-consuming it is to compile and maintain even the simplest data set & we are here to reduce and stabilize your most overlook costs",
    about: {
      whatWeOffer: "What we offer",
      compiledData: "Compiled Data",
      compiledData_text: "We provide open data, that in most cases you can compile easily all by yourself, yet not so easily maintain and keep up-to-date continuously. We compile data, add value to data, keep it updated, extract meaning out of it and serve you on demand via our api endpoints and other delivery methods. We have hand picked data of variety sets including geographical data, trivia data, point of interests, and more...",
      qualityAssurance: "Quality Assurance",
      qualityAssurance_text: "We collect data from a variety of sources, cross-check the ingredients and picked the most correct combination. We continuously check the validity of data, cross relate, update and archive it systematically so that you can access all attributes and versions at once anytime you need.",
      easeOfAccess: "Ease of Access",
      easeOfAccess_text: "We offer both downloadable data sets and transactional data delivery through our APIs. You can download the data in almost any format you can imagine including spreadsheets, CSV files, JSON documents and more. You can fine tune the attributes you need even for downloadable data sets and pay for the transaction & maintenance costs of only the data you need."
    },
    startNow: "START NOW"
  },
  login: {
    card_title: "Sign in to your account",
    forgot_password_text: "Forgot your password?",
    haveAccount_text: "Don't have an account?",
    stay_signed_text: "Stay signed in for a week"
  },
  register: {
    activateEmail: `We've sent an email to {email}. Open it up to activate your account.`,
    card_title: "Create your ViaApi account",
    card_title_activate: "Almost done...",
    privacy_text1: "Your personal data will be processed within the scope of the ",
    privacy_text2: "Clarification Text",
    privacy_text3: ". ",
    privacy_text4: "By clicking the 'Register' button, you confirm that you have read and accepted the ",
    privacy_text5: "Terms of Use",
    privacy_text6: ", ",
    privacy_text7: "Consent Text",
    privacy_text8: ", ",
    privacy_text9: "Cookie Policy",
    privacy_text10: ".",
    privacy_text: "Get emails from ViaApi about product updates, industry news, and events. If you change your mind, you can unsubscribe at any time.",
    announcement_text1: "I would like to receive electronic messages within the scope of the ",
    announcement_text2: "Consent Text",
    announcement_text3: " to be informed about important campaigns.",
    haveAccount_text: "Have an account?",
    clarificationTitle: "Clarification Text",
    clarificationText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    termsOfUseTitle: "Terms of Use",
    termsOfUseText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    cookiePolicyTitle: "Cookie Policy",
    cookiePolicyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    consentTitle: "Consent Text",
    consentText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    passwordStrength: "Make your password strong with having an upper case, at least one number and a symbol"
  },
  resetPassword: {
    card_title: "Reset your password",
    card_title_success: "Thanks, check your email for instructions to activate your account",
    cardBody_text: "Enter the email address created your account and we'll send you a link to reset your password.",
    cardBody_text_success: "Didn't get the email? Check your spam folder or",
    returnToLogin: "Return to sign in"
  },
  accountActivationCode: {
    activationCodeFail_text: "There was a problem with the activation link. Please type your email and we'll send you a new activation link.",
    card_title: "Almost done. We just emailed you.",
    card_title_success: "Thanks, check your email and open it up to reset your email",
    cardBody_text: "Please enter the code we emailed to {email}",
    cardBody_text_success: "Didn't get the email? Check your spam folder or",
    returnToLogin: "Return to sign in",
    newemail: "Please retype your new email"
  },
  catalog: {
    selectPlan_text: "You need to select a plan to see all of our catalog.",
    selectPlan: "Select Plan"
  },
  subscription: {
    additionalAttributes: "Additional Attributes",
    addPaymentMethod: "Add Now",
    askPassword: "You need to enter your password to see the details in this section.",
    cancelSubscription: "Cancel Now",
    close: "Change",
    activeSubscriptionAlert_title:'Active Subscription Alert',
    activeSubscriptionAlert_text:'You have an active subscription. To remove your payment method, either you need to have an alternative payment method or you need to cancel your subscription first.',
    cancelSubscription_text: "You subscription will be cancelled at the end of the month. You can continue accessing our APIs until the end of the month. If you want to continue with cancellation please click on the \"Cancel Now\" button.",
    currentPlan: "Your current plan",
    dataSets: "Data Sets",
    deletePaymentMethodDialog_title: "Delete payment method",
    deletePaymentMethodDialog_text: "Are you sure that you want to delete your card ending with {lastFourDigit}?",
    fileExport: "File Export (XLSX, PDF, CSV)",
    noPaymentMethod_title: "No payment method",
    noPaymentMethod_text: "You need to create a payment method.",
    paymentMethods: "Payment Methods",
    paymentMethodDialog_title: "Payment Method Needed",
    paymentMethodDialog_text: "You need to have a payment method on file in order to select a subscription.",
    prioritySupport: "Priority Support",
    requests: "Requests",
    selectPlan_text: "You don't have a plan yet",
    updatePaymentMethod: "Update Payment Method"
  },
  api: {
    apiId: "API Key ID",
    apiKeyName: "Api key name",
    createToken: "Create API Token",
    createTokenNow: "Create Now",
    createToken_text: "You need to create API token before you can make requests to our API.",
    documentation: "documentation",
    explanatory_text: "You need to create API token before you can make requests to our API. Learn more in our ViaAPI Service Documentation",
    learn_more: "click here",
    deleteApiKey_title: "Delete api key",
    deleteApiKey_text: "Are you sure you want to delete api key?",
    noTokens_text: "No API tokens",
    updateToken: "Update Api Token Description"
  },
  general: {
    about: "About",
    activationCode: "Activation code",
    address: "Address",
    api: "API",
    apiUsage: "API Usage",
    billing: "Billing",
    billingDetails: "Billing Details",
    cancel: "Cancel",
    cardNumber: "Card number",
    catalog: "Catalog",
    city: "City",
    close: "Close",
    contact: "Contact",
    company: "Company",
    companyName: "Company name",
    confirm: "Confirm",
    continue: "Continue",
    copied: "Copied",
    country: "Country",
    created: "Created",
    creditCard: "Credit Card",
    dashboard: "Dashboard",
    delete: "Delete",
    difficulty: "Difficulty",
    district: "District",
    downloadPdf: "Download PDF",
    sendEmail: "Send Email",
    email: "E-mail",
    emailChange: "Change Email",
    emailNew: "New Email",
    enterPassword: "Enter Password",
    expirationDate: "Expiration Date",
    expirationMonth: "Expiration Month",
    expirationYear: "Expiration Year",
    firstName: "First name",
    invoiceType: "Invoice type",
    invoices: "Invoices",
    openinvoice: "New Invoice",
    paidinvoice: "Paid Invoice",
    voidinvoice: "Void Invoice",
    lastName: "Last name",
    login: "Login",
    logout: "Logout",
    metrics: "Usage",
    month: "Month",
    name: "Full Name",
    nameOnCard: "Name on Card",
    notifications: "Notifications",
    or: "or",
    password: "Password",
    passwordChange: "Change Password",
    passwordConfirm: "Confirm new password",
    passwordNew: "New Password",
    passwordOld: "Current Password",
    person: "Person",
    personalInformation: "Personal Information",
    pricing: "Pricing",
    privacy: "Privacy & terms",
    privacyPolicy: "Privacy policy",
    profile: "Profile",
    profileSettings: "Profile Settings",
    resend: "Resend",
    resent: "Resent!",
    save: "Save",
    securityCode: "CVV2",
    select: "Select",
    selectCountry: "Select country",
    settings: "SETTINGS",
    signIn: "Sign in",
    signUp: "Sign up",
    state: "State",
    subscription: "Subscription",
    taxId: "VAT / Tax ID",
    thisMonth: "This Month",
    today: "Today",
    totalCalls: "Total Calls",
    question: "Question",
    hint:"Hint",
    word:"Word",
    update: "Update",
    verify: "Verify",
    year: "Year",
    zipCode: "Zipcode",
    onpackge:"You are on",
    passRequiredTag: "Your Password Required",
    passRequiredDesc: "Your need to type your password for this action",
    validateBtn: "Validate"
  },
  locales: {
    "en-us": "English",
    "tr-tr": "Türkçe"
  },
  notifications: {
    emailMeAbout: "Please select subjects that you want to get notified",
    notFound: "There are currently no new notifications",
    markAsReadAll: "Mark all as read",
    permissions : {
      invoiceReminder: "Invoice updates",
      limitReminder: "Api usage limits",
      paymentReminder: "Payment and payment method update",
      priceUpdatesReminder: "Promotions and catalog updates",
      subscriptionReminder: "Subscription updates"
    }
  },
  messages: {
    passwordMatch: "New password and its repetition must be equals.",
    passwordUpdateSuccess: "Password was changed successfully.",
    personalInformationUpdateSuccess: "Personal information was changed successfully.",
    permissionsUpdateSuccess: "Notification permissions was changed successfully."
  },
  account: {
    action: "Account Action",
    close: "Close Account",
    activate: "Re-activate Account",
    closeText: "Are you sure closing your account?"
  },
  client: {
    suspendTitle: "Client Suspended",
    suspendText: "Due to your billing card problems, your client account was suspended. Please check your billing information"
  }
}