<template>
  <header>
    <div class="navbar navbar-light va-navbar">
      <nav class="container d-flex flex-wrap flex-lg-nowrap justify-content-center">
        <router-link to="/" class="navbar-brand" href="#"><img src="@/assets/logo.png" alt=""></router-link>
        <div class="navbar-nav flex-row ms-sm-auto">
          <!-- <div class="nav-item col-md-auto me-2">
            <a class="nav-link mx-2" href="#about">{{$t("general.about")}}</a>
          </div> -->
          <!-- <div class="nav-item col-md-auto me-4 ">
            <a class="nav-link mx-2" href="#pricing">{{$t("general.pricing")}}</a>
          </div> -->
          <!-- <div class="nav-item col-md-auto">
            <router-link :to="{name: 'login'}" class="btn btn-outline-primary btn-login mx-2 px-4" v-if="!isLoggedin()">LOGIN</router-link>
            <router-link :to="{name: 'dashboard'}" class="btn btn-outline-primary btn-login d-flex align-items-center mx-2 px-4" v-else-if="isLoggedin"><span>{{$t("general.dashboard")}}</span> <arrow-right-icon></arrow-right-icon></router-link>
          </div> -->
        </div>
      </nav>
    </div>
    <div class="container header-text mt-4">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 mb-5 pe-md-4 text-center text-md-start mt-5">
          <h1 class="mb-4">{{$t("home.banner_title")}}</h1>
          <h6>{{$t("home.banner_text_1")}}</h6>
          <h6>{{$t("home.banner_text_2")}}</h6>
          <!-- <router-link :to="{name: 'register'}" class="btn btn-register btn-info mt-4">{{$t("home.startNow")}} <arrow-right-icon></arrow-right-icon></router-link> -->
        </div>
        <div class="col-md-6">
          <img src="@/assets/infographic.svg" alt="">
        </div>
      </div>
    </div>
    <div class="bg"></div>
  </header> 
</template>

<script>
  import { mapGetters } from "vuex";
  // import { ArrowRightIcon } from 'vue-feather-icons'
  export default {
    // components: {
    //   ArrowRightIcon
    // },
    methods: {
      ...mapGetters("auth", ["isLoggedin"])
    }
  }
</script>

<style>

</style>