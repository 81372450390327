import Vue from 'vue';
import Axios from 'axios';

import store from '@/store'

let headers = {
  'Accept': '*/*', 
  'Content-Type': 'application/json'
}

const API_DEV_LOCATION = "https://portal.api.dev.viaapi.com/v1";
const API_PROD_LOCATION = "https://portal.api.viaapi.com/v1";

const instance = Axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? API_DEV_LOCATION : API_PROD_LOCATION,
  headers
});

instance.interceptors.response.use(function (response) {
  if(response.data.sessionToken) {
    store.dispatch("auth/setToken", response.data.sessionToken);
  }
  
  return response;
}, function (error) {
  return Promise.reject(error);
});

Vue.$http = instance;

export default instance;