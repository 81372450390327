import axios from './axios';
import './lodash';
import './apexcharts';
import './i18n';

// Globally Registered Components
import './globals'

export default {
  axios
}