<template>
  <div class="my-5">
    <section id="about">
      <div class="container text-center">
        <h2 class="mb-5">{{$t("home.about.whatWeOffer")}}</h2>
        <div class="row">
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <compass-icon size="2.5x" class="text-primary mb-3"></compass-icon>
            <h5 class="mb-4">{{$t("home.about.compiledData")}}</h5>
            {{$t("home.about.compiledData_text")}}
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <award-icon size="2.5x" class="text-primary mb-3"></award-icon>
            <h5 class="mb-4">{{$t("home.about.qualityAssurance")}}</h5>
            {{$t("home.about.qualityAssurance_text")}}
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <feather-icon size="2.5x" class="text-primary mb-3"></feather-icon>
            <h5 class="mb-4">{{$t("home.about.easeOfAccess")}}</h5>
            {{$t("home.about.easeOfAccess_text")}}
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="pricing-section mt-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <card class="subscription-card mt-5 d-flex">
              <div class="d-flex flex-column justify-content-center">
                <table class="table va-table text-center">
                  <colgroup>
                    <col style="width: 20%">
                    <col style="width: 26%">
                    <col style="width: 26%">
                    <col style="width: 26%">
                  </colgroup>
                  <transition name="fade">
                    <tbody v-if="packages">
                      <tr>
                        <td></td>
                        <td v-for="(packageItem, index) in packages" :key="`package-name-${index}`">
                          <div class="d-flex flex-column align-items-center" style="min-height: 100px">
                            <h4 class="mb-auto">{{packageItem.details.name}}</h4>
                            <h6 class="fs-3 mb-2"><b>{{packageItem.details.summary}}</b></h6>
                            <!-- <button ref="selectButton" class="btn btn-primary px-5 mb-2" style="min-width: 140px; max-width: 200px" @click.prevent="onClickSelectButton(packageItem)">
                              {{$t("general.select")}}
                            </button> -->
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">{{$t("subscription.dataSets")}}</td>
                        <td v-for="(packageItem, index) in packages" :key="`package-dataset-${index}`">
                          {{packageItem.details.info.dataSets}}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">{{$t("subscription.requests")}}</td>
                        <td v-for="(packageItem, index) in packages" :key="`package-requests-${index}`">
                          {{packageItem.details.info.requests}}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">{{$t("subscription.additionalAttributes")}}</td>
                        <td v-for="(packageItem, index) in packages" :key="`package-requests-${index}`">
                          {{packageItem.details.info.additionalAttributes}}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">{{$t("subscription.fileExport")}}</td>
                        <td v-for="(packageItem, index) in packages" :key="`package-requests-${index}`">
                          <check-icon size="1.5x" v-if="packageItem.details.info.fileExport"></check-icon>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-start">{{$t("subscription.prioritySupport")}}</td>
                        <td v-for="(packageItem, index) in packages" :key="`package-requests-${index}`">
                          <check-icon size="1.5x" v-if="packageItem.details.info.prioritySupport"></check-icon>
                        </td>
                      </tr>
                    </tbody>
                  </transition>
                </table>
                <!-- <router-link :to="{name: 'register'}" class="btn btn-register btn-info mt-4 d-block">{{$t("home.startNow")}} <arrow-right-icon></arrow-right-icon></router-link> -->
              </div>              
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { CompassIcon, AwardIcon, FeatherIcon, CheckIcon } from 'vue-feather-icons'
import { mapActions } from 'vuex'
import Card from "@/components/VCard"

export default {
  name: 'Home',
  components: {
    CompassIcon,
    AwardIcon,
    FeatherIcon,
    CheckIcon,
    // ArrowRightIcon,
    Card
  },
  data() {
    return {
      packages: null,
    }
  },
  created() {
    this.getSubscriptionPackages().then(response => {
      this.packages = response.result.set;
    });
  },
  methods: {
    ...mapActions("app", ["getSubscriptionPackages"]),
    onClickSelectButton(packageItem) {
      console.log(packageItem);
    }
  }
}
</script>
