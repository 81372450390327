import MainProxy from '@/proxies/MainProxy';
import _ from "lodash"
import Vue from "vue";

const app = {
  namespaced: true,
  state: {
    catalog: [],
    paymentMethods: [],
    subscription: [],
    apitokens: [],
    lastAction: new Date,
    passChecked: false
  },
  getters: {
    getLastAction: (state) => {
      return state.lastAction
    },
    getPassValidateStatus: (state) => {
        let lastTime = Vue.$cookies.get('lastActionTime')
        if(lastTime){
          let diff=((new Date)-lastTime)/1000
          if(diff>600){ state.passChecked = false; return false } else { state.passChecked = true; return true }
        } else { return state.passChecked }
        },
    getCatalogItem: (state) => (name) => {
      return state.catalog.find(item => item.schema === name && item.locale === Vue.$http.defaults.headers.common['X-Language'])
    },
    defaultPaymentMethod: (state) => {
      return state.paymentMethods.find(item => item.status == 'a');
    }
  },
  actions: {
    getApiTokens({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().getApiTokens().then(response => {
          resolve(response)
          commit("setApitoken", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    createToken({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().createToken(payload.data).then(response => {
          resolve(response)
          commit("createToken", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateToken({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().updateToken(payload.data).then(response => {
          resolve(response)
          commit("updateToken", response.result.set);

        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteToken({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().deleteToken(payload.data).then(response => {
          resolve(response)
          commit("deleteToken",payload.data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    updatePassword({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().updatePassword(payload.data).then(response => {
          resolve(response)
          commit("updatePassword");
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSubscriptionPackages() {
      return new Promise((resolve, reject) => {
        return new MainProxy().getSubscriptionPackages().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteSubscription({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().deleteSubscription().then(response => {
          resolve(response);
          commit("setSubscription", null);
        }).catch(error => {
          reject(error)
        })
      })
    },
    createSubscription({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().createSubscription(payload).then(response => {
          resolve(response)
          commit("createSubscription", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSubscription({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().getSubscription().then(response => {
          resolve(response);
          commit("setSubscription", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateSubscription({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().updateSubscription(payload).then(response => {
            resolve(response);
          if(response.code=='200.0000'){
            commit("setSubscription", response.result.set);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    getBillingDetails() {
      return new Promise((resolve, reject) => {
        return new MainProxy().getBillingDetails().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getPaymentMethods({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().getPaymentMethods().then(response => {
          resolve(response);
          commit("setPaymentMethods", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    createPaymentMethod({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().createPaymentMethod(payload.data).then(response => {
          resolve(response);
          commit("createPaymentMethod", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    updatePaymentMethod({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().updatePaymentMethod(payload.data).then(response => {
          resolve(response);
          commit("updatePaymentMethod", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    checkCurrentPassword({commit}, payload){
      return new Promise((resolve, reject) => {
        return new MainProxy().passCheck(payload).then(response => {
          resolve(response)
          commit();
        }).catch(error => {
          reject(error)
        })
      })
    },
    deletePaymentMethod({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().deletePaymentMethod(payload.data).then(response => {
          resolve(response)
          commit("deletePaymentMethod",payload.data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    changeActivePaymentMethod({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().activatePaymentMethod(payload.data).then(response => {
          resolve(response);
          commit("changeActivePaymentMethod",payload.data.id);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getInvoices() {
      return new Promise((resolve, reject) => {
        return new MainProxy().getInvoices().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    emailInvoice({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().sendMailInvoice(payload).then(response => {
          resolve(response)
          commit("");
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCountries() {
      return new Promise((resolve, reject) => {
        return new MainProxy().getCountries().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCards() {
      return new Promise((resolve, reject) => {
        return new MainProxy().getCards().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getMetrics() {
      return new Promise((resolve, reject) => {
        return new MainProxy().getMetrics().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCatalog({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().getCatalog().then(response => {
          resolve(response);
          commit("setCatalog", response.result.set);
        }).catch(error => {
          reject(error)
        })
      })
    },
    setLastActionDate({commit}){
      commit("updateLastAction")
    },
    setPassValidated({commit}){
      commit("updatePassValidated")
    },
  },
  mutations: {
    createToken(state, token) {
      console.log("TOKEN CREATED");
      state.apitokens.push(token);
    },
    updateToken(state, token) {
      console.log("TOKEN UPDATED");
      let index = _.findIndex(state.apitokens, item => item.id == token.id);
      state.apitokens.splice(index, 1, token);
    },
    deleteToken(state, token) {
      console.log("TOKEN DELETED");
      let index = state.apitokens.findIndex(item => item.id == token.id);
      state.apitokens.splice(index, 1);
    },
    updatePassword() {
      console.log("PASSWORD UPDATED");
    },
    setCatalog(state, catalog) {
      state.catalog = catalog;
    },
    setPaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods || [];
    },
    createPaymentMethod(state, paymentMethod) {
      state.paymentMethods.push(paymentMethod);
    },
    updatePaymentMethod(state, paymentMethod) {
      let index = _.findIndex(state.paymentMethods, item => item.id == paymentMethod.id);
      state.paymentMethods.splice(index, 1, paymentMethod);
    },
    deletePaymentMethod(state, paymentMethod) {
      let index = _.indexOf(state.paymentMethods, paymentMethod);
      state.paymentMethods.splice(index, 1);
    },
    changeActivePaymentMethod(state, paymentMethod) {
      let active = _.findIndex(state.paymentMethods, item => item.id == paymentMethod);
      state.paymentMethods.forEach(function(part, index) {
        this[index].isCurrent = "n";
      }, state.paymentMethods);
      state.paymentMethods[active].isCurrent='y'
    },
    createSubscription(state, subscription) {
      state.subscription = subscription;
    },
    setSubscription(state, subscription) {
      state.subscription = subscription
    },
    setApitoken(state, token){
      state.apitokens = token
    },
    updateLastAction(state){
      state.lastAction = new Date
    },
    updatePassValidated(state){
      state.passChecked = true
    }
  }
}

export default app;