import Vue from 'vue'
import './plugins';
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from "@/plugins/i18n";
import VueCookies from 'vue-cookies';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './assets/scss/main.scss'

Vue.use(VueCookies);

Vue.config.productionTip = false

// Check loggedin status...
store.dispatch('auth/check').then(() => {
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});

// Check preferred locale...
store.dispatch('global/checkLocale');
i18n.locale = store.getters["global/getLocale"];


