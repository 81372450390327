<template>
  <div class="card">
    <div class="card-header" v-if="hasHeaderSlot">
      <slot name="header"></slot>
    </div>
    <div class="card-body">
      <h5 class="card-title mb-4" v-if="hasBodyTitleSlot">
        <slot name="bodyTitle"></slot>
      </h5>
      <p class="card-text" v-if="hasBodyTextSlot">
        <slot name="bodyText"></slot>
      </p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      hasHeaderSlot() {
        return !!this.$slots.header
      },
      hasBodyTitleSlot() {
        return !!this.$slots.bodyTitle
      },
      hasBodyTextSlot() {
        return !!this.$slots.bodyText
      }
    }
  }
</script>

<style lang="scss" scoped>
 
 .card {
   border: none;
   box-shadow: 0 8px 24px rgba(0,0,0,.1);
   .card-header {
     background: #FFFFFF;
     padding-top: 1.5rem;
    }
 }

</style>