import MainProxy from '@/proxies/MainProxy';
import Vue from 'vue'
import _ from 'lodash'

const auth = {
  namespaced: true,
  state: {
    authenticated: false,
    sessionKey: null,
    me: {
      uuid: null,
      firstName: null,
      middleName: null,
      lastName: null,
      email: null,
      image: "/assets/images/avatar.png",
      lang: {
        name: "English",
        flag: "/assets/images/english-flag.png",
      },
      status: null,
      notificationPermissions: {
        invoiceReminder: "y",
        limitReminder: "y",
        paymentReminder: "y",
        priceUpdatesReminder: "y",
        subscriptionReminder: "y"
      },
      notifications: []
    },
  },
  getters: {
    isLoggedin: state => {
      return state.authenticated;
    },
    getUserStatus: state => {
      return state.me.status
    },
    getUserPermissions: state => {
      return state.me.notificationPermissions
    },
    getUserNotifications: state => {
      return state.me.notifications
    }
  },
  actions: {
    async check({commit,state,dispatch}) {
      await commit('check');
      if(state.authenticated) {
        await dispatch('global/init', {}, {root: true})
      }
    },
    setToken({commit}, token) {
      commit("setToken", token)
    },
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().login(payload.data).then(response => {
          commit('setMe', response.result.set)
          commit('login', response.sessionToken)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    register({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().register(payload.data).then(response => {
          commit('setMe', response.result.set)
          commit('login')
          commit("register");
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    reset({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().reset(payload.data).then(response => {
          commit('register', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    update({commit}, payload){
      return new Promise((resolve, reject) => {
        return new MainProxy().updateProfile(payload.data).then(response => {
          commit('setMe', response.result.set)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updatePermissions({commit}, payload){
      return new Promise((resolve, reject) => {
        return new MainProxy().updatePermissions(payload.data).then(response => {
          commit('setMyPermissions', payload.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    dismissAllNotifications({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().dismissNotifications().then(response => {
          commit('setMyNotifications', [])
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    dismissNotification({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().dismissSingleNotification(payload.data).then(response => {
          commit('dismissSingleNotification', payload.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    recover({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().recover(payload.data).then(response => {
          commit('recover');
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    activate({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().activate(payload.data).then(response => {
          commit('activate');
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    resendActivation({commit}, payload) {
      return new Promise((resolve, reject) => {
        return new MainProxy().resendActivation(payload.data).then(response => {
          commit('setEmail', payload.data);
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    logout({commit}) {
      commit('logout');
      if(Vue.router.currentRoute.name != 'login'){
        Vue.router.push({
          name: 'login',
        })
      }
    },
    closeUserAccount({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().deleteAccount().then(response => {
          resolve(response)
          commit("updateClientStatus",'r');
        }).catch(error => {
          reject(error)
        })
      })
    },
    rescueUserAccount({commit}) {
      return new Promise((resolve, reject) => {
        return new MainProxy().activateAccount().then(response => {
          resolve(response)
          commit("updateClientStatus",'a');
        }).catch(error => {
          reject(error)
        })
      })
    },
    changeLang({ commit }, lang) {
      commit('setLang', lang);
    }
  },
  mutations: {
    setLang(state, lang) {
      state.me = {
        ...state.me,
        lang: lang
      }
    },
    setMe(state, data) {
      let myData = {
        uuid: data.uuid,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        email: data.email,
        image: "/assets/images/avatar.png",
        lang: {
          name: "English",
          flag: "/assets/images/english-flag.png",
        },
        status: data.status,
        clientStatus: data.client === undefined ? '' : data.client.status,
        notificationPermissions: data.notificationPermissions,
        notifications: data.notifications
      }

      state.me = myData
    },
    check(state) {
      state.authenticated = !!localStorage.getItem('X-Session-Key')
      if (state.authenticated) {
        let sessionKey = localStorage.getItem('X-Session-Key')
        state.sessionKey = sessionKey

        if(!Vue.$http.defaults.headers.common['X-Session-Key']) {
          Vue.$http.defaults.headers.common['X-Session-Key'] = sessionKey
        }
      }
    },
    login(state) {
      state.authenticated = true
    },
    setToken(state, key) {
      state.sessionKey = key
      localStorage.setItem('X-Session-Key', key)
      Vue.$http.defaults.headers.common['X-Session-Key'] = key
    },
    register() {

    },
    logout(state) {
      state.authenticated = false
      localStorage.removeItem('X-Session-Key')
      state.sessionKey = null
      state.me = {
        uuid: null,
        name: null,
        middleName: null,
        surname: null,
        email: null,
        image: "/assets/images/avatar.png",
        lang: {
          name: "English",
          flag: "/assets/images/english-flag.png",
        },
        status: null,
        notificationPermissions: null,
        notifications: []
      }
      delete Vue.$http.defaults.headers.common['X-Session-Key']
    },
    recover() {
      // console.log("Password recovered!");
    },
    activate(state) {
      state.me.status = "a";
    },
    setEmail(state, payload) {
      state.me.email = payload.email
    },
    setMyPermissions(state, permissions) {
      state.me.notificationPermissions = permissions
    },
    setMyNotifications(state, notifications) {
      state.me.notifications = notifications
    },
    dismissSingleNotification(state, notificationId) {
      var myNotifications = _.filter(state.me.notifications, (n) => {
        return n.id != notificationId
      })
      state.me.notifications = myNotifications
    },
    updateClientStatus(state,status){
      state.me.clientStatus = status
    }
  },
}

export default auth