import Proxy from './Proxy';

class MainProxy extends Proxy {
  constructor(parameters = {}) {
    super('', parameters);
  }

  init() {
    return this.submit('get', 'init');
  }

  login(payload) {
    return this.submit('post', 'login', payload);
  }

  passCheck(payload) {
    return this.submit('post', 'password/check', payload);
  }

  register(payload) {
    return this.submit('post', 'register', payload);
  }

  reset(payload) {
    return this.submit('post', 'password/reset', payload);
  }

  recover(payload) {
    return this.submit('post', 'password/recovery', payload);
  }

  activate(payload) {
    return this.submit('patch', 'account/activate/'+payload.userUuid+'/'+payload.activationCode,payload);
  }

  resendActivation(payload) {
    return this.submit('post', 'account/code/resend', payload);
  }

  updateProfile(payload) {
    return this.submit('put', 'profile', payload);
  }

  updatePermissions(payload) {
    return this.submit('put', 'notification-settings', payload)
  }

  updatePassword(payload) {
    return this.submit('put', 'password', payload);
  }

  getApiTokens() {
    return this.submit('get', 'api-tokens');
  }

  createToken(payload) {
    return this.submit('post', 'api-token', payload);
  }

  deleteToken(payload) {
    return this.submit('delete', `/api-token/${payload.id}`);
  }

  updateToken(payload) {
    return this.submit('put', `/api-token/${payload.id}`, payload);
  }

  getSubscription() {
    return this.submit('get', '/subscription');
  }

  updateSubscription(payload) {
    return this.submit('patch', `subscription/package/${payload.id}`);
  }

  deleteSubscription() {
    return this.submit('delete', '/subscription');
  }

  createSubscription(payload) {
    return this.submit('post', '/subscription', payload);
  }

  getSubscriptionPackages() {
    return this.submit('get', '/subscription/packages');
  }

  getBillingDetails() {
    return this.submit('get', '/billing/details');
  }

  getPaymentMethods() {
    return this.submit('get', '/payment-methods');
  }

  createPaymentMethod(payload) {
    return this.submit('post', '/payment-method', payload);
  }

  updatePaymentMethod(payload) {
    return this.submit('put', `/payment-method/${payload.id}`, payload);
  }

  activatePaymentMethod(payload) {
    return this.submit('patch', `/payment-method/${payload.id}/current`, payload);
  }

  deletePaymentMethod(payload) {
    return this.submit('delete', `/payment-method/${payload.id}`);
  }

  deleteAccount(){
    return this.submit('delete', 'profile');
  }

  activateAccount(){
    return this.submit('patch', 'profile');
  }

  getInvoices() {
    return this.submit('get', '/invoices');
  }

  sendMailInvoice(id) {
    return this.submit('post', '/invoice',{"id":id});
  }

  getCountries() {
    return this.submit('get', '/location/countries');
  }

  getCards() {
    return this.submit('get', '/cards');
  }

  getMetrics() {
    return this.submit('get', '/metrics');
  }

  getCatalog() {
    return this.submit('get', '/catalogs');
  }

  dismissNotifications() {
    return this.submit('patch', '/notification/dismissAll');
  }

  dismissSingleNotification(payload) {
    return this.submit('patch', `/notification/${payload}/dismiss`);
  }
}

export default MainProxy;
