<template>
  <div class="main">
    <the-header></the-header>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "./TheWebHeader"
import TheFooter from "./TheWebFooter"
export default {
  components: {
    TheHeader,
    TheFooter
  }
}
</script>