import Vue from "vue";
import VueI18n from "vue-i18n";
import {tr, en} from '@/plugins/i18n/localizations'
import trValidation from "vee-validate/dist/locale/tr.json";
import enValidation from "vee-validate/dist/locale/en.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language.includes("-") ? navigator.language : `${navigator.language}-${navigator.language}`,
  fallbackLocale: "en-us",
  messages: {
    "tr-tr": {
      ...tr,
      validation: trValidation.messages
    },
    "en-us": {
      ...en,
      validation: enValidation.messages
    }
  }
});

export { i18n };
