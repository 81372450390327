import Vue from 'vue';
import store from '@/store';
// import Notifications from 'vue-notifyjs'

class BaseProxy {
  constructor(endpoint, parameters = {}) {
    this.endpoint = endpoint;
    this.parameters = parameters;
  }

  setParameters(parameters) {
    Object.keys(parameters).forEach((key) => {
      this.parameters[key] = parameters[key];
    });
    return this;
  }

  setParameter(parameter, value) {
    this.parameters[parameter] = value;
    return this;
  }

  removeParameters(parameters) {
    parameters.forEach((parameter) => {
      delete this.parameters[parameter];
    });
    return this;
  }

  removeParameter(parameter) {
    delete this.parameters[parameter];

    return this;
  }

  submit(requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      Vue.$http[requestType](url + this.getParameterString(), data)
        .then((response) => {
          resolve(response.data);
        })
        .catch(({
          response
        }) => {
          switch (response.data.code) {
            case "401":
            case "401.0001":
            case "401.0002":
            case "401.9000":
              store.dispatch('auth/logout');
              break;
          }
          reject(response.data);
        });
    });
  }

  all() {
    return this.submit('get', `/${this.endpoint}`);
  }

  find(id) {
    return this.submit('get', `/${this.endpoint}/${id}`);
  }

  create(item) {
    return this.submit('post', `/${this.endpoint}`, item);
  }

  patchProperty(item) {
    if (item.id) {
      return this.submit('patch', `/${this.endpoint}/${item.id}/${item.propertyKey}/${item.propertyValue}`);
    } else {
      return this.submit('patch', `/${this.endpoint}/${item.propertyKey}/${item.propertyValue}`);
    }
  }

  update(item) {
    return this.submit('put', `/${this.endpoint}/${item.id}`, item);
  }

  destroy(id) {
    return this.submit('delete', `/${this.endpoint}/${id}`);
  }

  getParameterString() {
    const parameterString = Object.keys(this.parameters).map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(this.parameters[k])
    }).join('&')
    return parameterString.length === 0 ? '' : `?${parameterString}`;
  }
}

export default BaseProxy;