<template>
  <footer class="footer mt-auto bg-white border-top">
    <nav class="navbar navbar-expand-sm navbar-light d-flex flex-column justify-content-center">
      <div class="navbar-nav">
        <div class="nav-item">
          <router-link class="nav-link mx-2" to="/">© ViaApi</router-link>
        </div>
        <div class="nav-item">
          <a class="nav-link mx-2" href="mailto:info@viaapi.com">{{ $t('general.contact') }}</a>
        </div>
        <!-- <div class="nav-item">
          <router-link class="nav-link mx-2" to="/privacy">{{ $t('general.privacy') }}</router-link>
        </div> -->
        <div class="nav-item dropup va-dropdown">
          <button type="button" class="btn nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img :src="require(`@/assets/locales/${$i18n.locale}.svg`)" width="16" height="16" alt="" class="me-1"> {{$t(`locales.${$i18n.locale}`)}}
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDarkDropdownMenuLink">
            <li v-for="(locale, index) in locales" :key="`locale-${index}`"><a class="dropdown-item" href="#" @click.prevent="onClickLocaleDropdown(locale)"> <img :src="require(`@/assets/locales/${locale}.svg`)" width="16" height="16" alt="" class="me-1"> {{$t(`locales.${locale}`)}}</a></li>
          </ul>
        </div>
      </div>
      <p>2054 Sunset Lake Road, Suite B-2, Newark, DE - 19702, USA.</p>
    </nav>
  </footer>
</template>

<script>
import localeChangerMixin from "@/mixins/localeChangerMixin"
  export default {
    mixins: [localeChangerMixin]
  }
</script>

<style>

</style>