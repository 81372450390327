import { i18n } from "@/plugins/i18n";
import MainProxy from '@/proxies/MainProxy';
import Vue from "vue";

const global = {
  namespaced: true,
  state: {
    locale: i18n.locale
  },
  getters: {
    getLocale(state) {
      return state.locale
    }
  },
  actions: {
    checkLocale({commit}) {
      commit('checkLocale')
    },
    setLocale({commit}, locale) {
      commit("setLocale", locale)
    },
    init({commit}) {
      return new Promise((resolve, reject) => {
          return new MainProxy().init().then(response => {
            commit("auth/setMe", response.result.set, {root: true});
            resolve(response);
          }).catch(error => {
            reject(error)
            commit('auth/logout', this.state, {root: true})
            window.location.assign('/');
          })
      })
    }
  },
  mutations: {
    checkLocale(state) {
      state.locale = localStorage.getItem('X-Language') || 'en-us';
      Vue.$http.defaults.headers.common['X-Language'] = state.locale;
    },
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem('X-Language', locale);
      Vue.$http.defaults.headers.common['X-Language'] = state.locale;
    }
  }
}

export default global;